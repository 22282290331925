 /**
 * 用于清除浏览器默认样式文件
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
 @font-face {
   font-family: FZY4JW;
   src: url('./assets//font/FZY4JW--GB1-0.TTF');
 }

 html,
 body,
 div,
 span,
 applet,
 object,
 iframe,
 p,
 blockquote,
 pre,
 a,
 abbr,
 acronym,
 address,
 big,
 cite,
 code,
 del,
 dfn,
 em,
 img,
 ins,
 kbd,
 q,
 s,
 samp,
 small,
 strike,
 strong,
 sub,
 sup,
 tt,
 var,
 b,
 u,
 i,
 center,
 dl,
 dt,
 dd,
 ol,
 ul,
 li,
 fieldset,
 form,
 input,
 button,
 label,
 legend,
 table,
 caption,
 tbody,
 tfoot,
 thead,
 tr,
 th,
 td,
 article,
 aside,
 canvas,
 details,
 embed,
 figure,
 figcaption,
 footer,
 header,
 hgroup,
 menu,
 nav,
 output,
 ruby,
 section,
 summary,
 time,
 mark,
 audio,
 video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   vertical-align: baseline;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   margin: 0;
   padding: 0;
   border: 0;
   vertical-align: baseline;

 }

 /* HTML5 display-role reset for older browsers */
 article,
 aside,
 details,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 menu,
 nav,
 section {
   display: block;
 }

 ol,
 ul,
 li {
   list-style: none;
 }

 blockquote,
 q {
   quotes: none;
 }

 blockquote:before,
 blockquote:after,
 q:before,
 q:after {
   content: '';
   content: none;
 }

 table {
   border-collapse: collapse;
   border-spacing: 0;
 }

 /* custom */
 a {
   color: #7e8c8d !important;
   text-decoration: none !important;
   -webkit-backface-visibility: hidden !important;
 }

 ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
 }

 ::-webkit-scrollbar-track-piece {
   background-color: rgba(0, 0, 0, 0.2);
   -webkit-border-radius: 6px;
 }

 ::-webkit-scrollbar-thumb:vertical {
   height: 5px;
   background-color: rgba(125, 125, 125, 0.7);
   -webkit-border-radius: 6px;
 }

 ::-webkit-scrollbar-thumb:horizontal {
   width: 5px;
   background-color: rgba(125, 125, 125, 0.7);
   -webkit-border-radius: 6px;
 }

 html,
 body {
   font-size: 10px;
   color: transparent;
   width: 100%;
   font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑", sans-serif;
   height: auto;
   overflow-x: hidden;
   background-color: #fff;
   position: relative;
 }


 body {
   line-height: 1;
   -webkit-text-size-adjust: none;
   -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
 }

 html {
   scrollbar-width: thin;
 }

 /*清除浮动*/
 .clearfix:before,
 .clearfix:after {
   content: " ";
   display: inline-block;
   height: 0;
   clear: both;
   visibility: hidden;
 }

 .clearfix {
   *zoom: 1;
 }

 /*隐藏*/
 .dn {
   display: none;
 }

 .ant-modal-mask {
   background-color: #000000d1 !important
 }



 //  Drawer 浮层设置了透明色，方便自定义
 .head-drawer-supernatant {
   background-color: #000000cb !important;

   .ant-drawer-content {
     background-color: #000000cb !important;
   }

   .ant-drawer-body {
     padding: 0 !important;
   }
 }


 .page-message {
   position: fixed;
   top: 30vh;
   left: 50%;
   transform: translateX(-50%);
   z-index: 9999;

   .cont {
     background: #000000ea !important;
     width: 6rem;
     height: auto;
     padding: 0.16rem 0.12rem;
     color: #fff;
     text-align: center;
     font-size: 0.3rem;
     border-radius: 0.08rem;
     transition: all 0.3s;
   }
 }



 .se {
   display: none;
 }

 .ant-tooltip {
   --antd-arrow-background-color: rgb(255, 255, 255) !important;

   .ant-tooltip-inner {
     color: #000 !important;
     background-color: #fff !important;
     font-size: 0.2rem !important;
     width: max-content;
     line-height: 0.5rem;
     padding: 0 0.2rem !important;
   }
 }




 .pop {
   pointer-events: auto;
   width: 9.5rem;
   background-color: #fff;
   padding: 0.3rem 0;
   position: relative;
   border-radius: 0.1rem;

   p {
     margin-bottom: 0;
   }

   .title {
     font-size: 0.4rem;
     color: #000000;
     text-align: center;
     font-weight: bold;
     margin-bottom: 0.18rem;
   }

   .line {
     position: relative;
     width: 95%;
     margin: auto;
     height: 0.01rem;
     background: linear-gradient(to left, transparent, #e4e2e2, transparent);
     margin-bottom: 0.4rem;

     &::after {
       content: "";
       width: 1.74rem;
       height: 0.04rem;
       background: linear-gradient(to top, #12c7ff, #7efaff);
       position: absolute;
       left: 0;
       right: 0;
       margin: auto;
       top: -0.04rem;
     }
   }

   .close {
     position: absolute;
     background: url('./assets/close.png') no-repeat;
     background-size: 100% 100%;
     width: 0.54rem;
     height: 0.54rem;
     right: -0.68rem;
     top: -0.34rem;
     cursor: pointer;
   }

 }

 .confirm-dialog-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
   color: #000;

   .confirm-dialog {
     background: #FFFFFF;
     box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.3);
     border-radius: 0.12rem;
     width: 9.2rem;
     text-align: center;
     overflow: hidden;

     .confirm-success {
      width: 9.2rem;
      height: 1.4rem;
      background: url('./assets/pay-success-icon.png') no-repeat center center;
      background-size: contain;
      display: flex;
      justify-content: center;
      margin-top: 0.76rem;
     }

     .confirm-error {
      width: 9.2rem;
      height: 1.4rem;
      background: url('./assets/pay-error-icon.png') no-repeat center center;
      background-size: contain;
      display: flex;
      justify-content: center;
      margin-top: 0.76rem;
     }

     .confirm-title {
       margin: 0.66rem 0;
       padding: 0 0.66rem;
       font-family: FZY4JW;
       font-weight: normal;
       font-size: 0.46rem;
       color: #333333;
       line-height: 0.48rem;
       &.success{
        margin: 0.56rem 0 0.5rem 0;
       }
       &.error{
        margin: 0.56rem 0 0.5rem 0;
       }
     }

     .confirm-text {
       font-family: FZY4JW;
       font-weight: normal;
       font-size: 0.36rem;
       color: #666666;
       line-height: 0.7rem;
       padding: 0 0.66rem;
     }
   }

   .confirm-dialog-buttons {
     margin-top: 0.85rem;
     border-top: 0.02rem solid #E0E0E0;
  
     button {
       height: 1.6rem;
       border: none;
       background-color: #fff;
       cursor: pointer;
       font-family: FZY4JW;
       font-weight: normal;
       font-size: 0.46rem;
       color: #666666;
       line-height: 0.7rem;
       border-right: 0.02rem solid #E0E0E0;
       &:last-child{
        border: none;
       }
     }
     .cancel-btn{
      color: #CCCCCC;
     }
   }
 }
@primary-color: #181616;